<template>
  <div class="d-lg-none">
    <div v-for="row in rows" :key="row" class="list-group mb-2">
      <div class="list-group-item" style="height: 105px"></div>
      <div class="list-group-item d-flex" style="height: 39.39px"></div>
    </div>
  </div>
  <div class="d-none d-lg-block">
    <div v-for="row in rows" :key="row" class="list-group mb-2">
      <div class="list-group-item" style="height: 63px"></div>
      <div class="list-group-item d-flex" style="height: 39.39px"></div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "TxnSummarySkeleton",
  components: {},
  setup() {
    return {
      rows: parseInt(process.env.VUE_APP_API_LIMIT),
    };
  },
});
</script>
